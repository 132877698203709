<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At
        <stemble-latex content="$20.0^\circ\text{C,}$" />
        the concentration of dissolved oxygen in water exposed to gaseous oxygen at a partial
        pressure of
        <stemble-latex content="$101\,\text{kPa}$" />
        is
        <stemble-latex content="$1.38\times 10^{-3}\,\text{mol/L.}$" />
        Use Henry's law to determine the solubility of oxygen in water when its partial pressure is
        <number-value :value="pressure" unit="\text{kPa.}" />
      </p>

      <calculation-input
        v-model="inputs.O2sol"
        prepend-text="$\text{Solubility:}$"
        append-text="$\text{mol/L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question409',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        O2sol: null,
      },
    };
  },
  computed: {
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
  },
};
</script>
